import React from 'react';
import Img from 'gatsby-image';

export default (props) => (
    <section className="text-blocks-section">
        <div className="container">
            <div className="row">
                <div className="text-blocks-wrap">
                    {(props.blocks ? props.blocks.map(item => (

                        <div className="text-block">
                            <div className="text">
                                <h2 dangerouslySetInnerHTML={{ __html: item.title }}></h2>
                                <p>{ item.text }</p>                            
                            </div>
                            <div className="image">
                                <Img fixed={ ( item.img ? item.img.localFile.childImageSharp.fixed : null) } alt={ (item.img ? item.img.alt_text : null) } />
                            </div>
                        </div>
                        
                    )) : null )}
                </div>
                <hr/>
            </div>
        </div>
    </section>
   
);