import React, { Component } from "react"
import ModalMessage from './../modal-message/ModalMessage';

class NewsletterItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visibleModal: false,
            subscribeAllow: true,
            message: '',
            disabledButton: false,
            buttonText: 'Wyślij'
        }
    }

    toogleModal() {
        this.setState( prevState => ({  
            visibleModal: !prevState.visibleModal
        }))
    }

    saveSubscriber(e) {
        e.preventDefault();
        if ( this.state.subscribeAllow ) {
            this.setState({
                disabledButton: true,
                buttonText: 'Wysyłam...'
            })
            const email = document.getElementsByName('email')[0].value;
            const data = 'email=' + email;
            const xhr = new XMLHttpRequest();
            const This= this;

            xhr.open("POST", this.props.home + "/wp-admin/admin-ajax.php?action=save_subscriber", true);
            xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
            xhr.send(data);

            xhr.addEventListener('load', function() {
                console.log(xhr.response);
                if ( xhr.response == 0 ) {
                    This.setState({
                        subscribeAllow: true,
                        visibleModal: true,
                        message: 'Przykro nam, istnieje już taki email w bazie.',
                        buttonText: 'Wyślij',
                        disabledButton: false,

                    });

                } else {
                    This.setState({
                        visibleModal: true,
                        subscribeAllow: false,
                        message: 'Dziękujemy za zapis do newslettera.',
                        buttonText: 'Wysłano',
                        disabledButton: true,
                    });
                }
            });
        }
    } 
    

    render() {

        return(
            <section class="newsletter-wrap">
                <ModalMessage message={ this.state.message } visible={ this.state.visibleModal } closeModal={ this.toogleModal.bind(this) } />
                <div class="container newsletter">
                    <div class="row">
                        <div class="col-lg-6" data-aos="flip-right" data-aos-duration="800" dangerouslySetInnerHTML={{ __html: this.props.data.content }}></div>
                        <div class="col-lg-6" data-aos="flip-left" data-aos-duration="800">
                            <form action={ this.props.home + "/wp-json/wp/v2/save-subscriber"} type="POST" onSubmit={this.saveSubscriber.bind(this)}>
                                <label for="email">
                                    <span className="label-hide">Podaj swój adres e-mail</span>
                                    <input  id="email" type="email" placeholder="Podaj swój adres e-mail" name="email" required autocomplete="off" />
                                </label>
                                <button type="submit" disabled={ this.state.disabledButton } > { this.state.buttonText } </button>
                                <label for="rule">
                                    <input type="checkbox" name="rule" id="rule" required />
                                    <small>{this.props.data.acf.zgoda}</small>
                                </label>
                            </form>
                        </div>
                        <div class="col">
                            <hr />
                        </div>
                    </div>
                </div>
            </section>
            
        )
    }
}

export default NewsletterItem;