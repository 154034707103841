
import React, {  Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import NewsletterItem from './NewsletterItem';

class Newsletter extends Component {

    render() {
        var settings = {
            dots: true,
            infinite: true,
            arrows: false,
            speed: 500,
            // autoplay: true,
            className: 'slider owl-carousel',
            dotsClass: 'owl-dots',
            slidesToShow: 1,
            slidesToScroll: 1
          };

        var query = graphql`
            query {
                wordpressWpNewsletter {
                    content
                    acf {
                        zgoda
                    }
                }
            }
        `;
        return (
            <>
                <StaticQuery 
                    query={query}
                    render={data => (
                        <NewsletterItem home={ this.props.home } data={data.wordpressWpNewsletter}/>
                    )}
                />
            </>
        );
    }

}

export default Newsletter;