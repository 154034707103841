import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import PortfolioBanner from './../components/portfolio-banner/PortfolioBanner'
import Advantages from './../components/advantages/Advantages'
import SimpleText from './../components/simple-text/SimpleText'
import Team from './../components/team/Team'
import TextBlocks from './../components/text-blocks/TextBlocks'
import JoinToUs from './../components/join-to-us/JoinToUs'
import Newsletter from '../components/newsletter/Newsletter'
import SEO from '../components/seo'

class AboutTemplate extends Component {
  setFooterMargin() {
    document.querySelector('footer').style.marginTop = '-245px'
  }

  componentDidMount() {
    this.setFooterMargin()
  }

  render() {
    const currentPage = this.props.data.wordpressPage
    const homepage = this.props.data.wordpressSiteMetadata.home
    console.log(currentPage)

    return (
      <Layout>
        <SEO
          title={
            currentPage.title +
            ' | Kuchnie na wymiar Śląsk, Małopolska | Meble kuchenne Kraków'
          }
        />
        <PortfolioBanner data={currentPage.featured_media} />
        <div className="take-up">
          <SimpleText text={currentPage.content} />
          <Advantages data={currentPage.acf.advantages} cols={3} />
          <Team workers={currentPage.acf.workers} />
          <TextBlocks blocks={currentPage.acf.text_blocks} />
          <JoinToUs
            title={currentPage.acf.join_us_title}
            text={currentPage.acf.join_us_text}
            imageData={currentPage.acf.join_us_image}
          />
          <Newsletter home={homepage} />
        </div>
      </Layout>
    )
  }
}

export default AboutTemplate

export const pageQuery = graphql`
  query($id: String) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      featured_media {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1920, maxHeight: 1080, quality: 95) {
              srcSet
            }
          }
        }
      }
      acf {
        join_us_title
        join_us_text
        join_us_image {
          alt_text
          localFile {
            childImageSharp {
              fixed(width: 750, height: 422, cropFocus: CENTER, quality: 90) {
                src
              }
            }
          }
        }
        text_blocks {
          title
          text
          img {
            alt_text
            localFile {
              childImageSharp {
                fixed(width: 750, height: 422, quality: 90) {
                  src
                }
              }
            }
          }
        }
        workers {
          name
          job
          text
          img {
            alt_text
            localFile {
              childImageSharp {
                fixed(width: 490, height: 506, quality: 90) {
                  src
                }
              }
            }
          }
        }
        advantages {
          text
          icon {
            wordpress_id
            alt
            url {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
    wordpressSiteMetadata {
      home
    }
    site {
      id
      siteMetadata {
        title
      }
    }
  }
`
