import React from 'react';
import Img from 'gatsby-image';
export default (props) => (
    <section className="team-section">
        <div className="container">
            <div className="row">
                <div className="team-wrap">
                    {(props.workers ? props.workers.map(item => (

                        <div className="team">
                            <div className="team-img">
                                <Img fixed={ item.img.localFile.childImageSharp.fixed } alt={ item.img.alt_text } />
                                <div className="info">
                                    <h2>{ item.name }</h2>
                                    <h5>{ item.job }</h5>
                                </div>
                            </div>
                            <p>
                                { item.text }
                            </p>
                        </div>
                        
                    )) : null)}
                </div>
            </div>
        </div>
    </section>
   
);